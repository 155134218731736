var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.authed)?_c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"_id","options":_vm.options,"server-items-length":_vm.totalItems,"dense":"","footer-props":{ itemsPerPageOptions: [20, 40, 60] },"items-per-page":20,"fixed-header":"","loading":_vm.loading,"loading-text":"加载中..."},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex flex-wrap pl-2 pb-1",staticStyle:{"gap":"16px"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":10,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"flex":"0 1 auto","width":"240px"},attrs:{"value":_vm.pdates.join(' ~ '),"label":"日期","readonly":"","hide-details":"","append-icon":"mdi-close"},on:{"click:append":function($event){_vm.pdates=[]}}},'v-text-field',attrs,false),on))]}}],null,false,3045378896),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"range":"","locale":"zh-cn","scrollable":""},on:{"change":function($event){_vm.menu1=false}},model:{value:(_vm.pdates),callback:function ($$v) {_vm.pdates=$$v},expression:"pdates"}})],1),_c('v-text-field',{staticStyle:{"flex":"0 1 auto"},attrs:{"label":"订单号","clearable":"","hide-details":""},model:{value:(_vm.orderid),callback:function ($$v) {_vm.orderid=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"orderid"}}),_c('v-text-field',{staticStyle:{"flex":"0 1 auto"},attrs:{"label":"主题","clearable":"","hide-details":""},model:{value:(_vm.title),callback:function ($$v) {_vm.title=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"title"}}),_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.loadData($event)}}},[_vm._v("查询")])],1),_c('v-divider')]},proxy:true},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTime(item.time))+" ")]}},{key:"item.content",fn:function(ref){
var item = ref.item;
return [_c('vue-json-pretty',{attrs:{"data":item.content,"deep":0}})]}}],null,false,1305523972)})],1):_c('div',[_vm._v(" 无此权限 ")])}
var staticRenderFns = []

export { render, staticRenderFns }