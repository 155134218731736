<template>
	<div v-if="authed">
        <v-data-table :headers="headers" :items="items" item-key="_id"
			:options.sync="options" :server-items-length="totalItems" dense
			:footer-props="{ itemsPerPageOptions: [20, 40, 60] }" :items-per-page="20" fixed-header
			:loading="loading" loading-text="加载中...">
            <template v-slot:top>
				<div class="d-flex flex-wrap pl-2 pb-1" style="gap:16px">
					<v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="10"
						transition="scale-transition" offset-y min-width="290px">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field :value="pdates.join(' ~ ')" label="日期" v-on="on" v-bind="attrs" readonly hide-details
								append-icon="mdi-close" @click:append="pdates=[]" style="flex: 0 1 auto;width:240px;"></v-text-field>
						</template>
						<v-date-picker v-model="pdates" @change="menu1=false" range locale="zh-cn" scrollable></v-date-picker>
					</v-menu>
					<v-text-field v-model.trim="orderid" label="订单号" clearable hide-details style="flex: 0 1 auto"></v-text-field>
					<v-text-field v-model.trim="title" label="主题" clearable hide-details style="flex: 0 1 auto"></v-text-field>
					<!--<v-text-field v-model="cond" label="条件" clearable dense hide-details></v-text-field>-->
					<v-btn color="primary" @click.stop="loadData" class="mt-2">查询</v-btn>
				</div>
                <v-divider/>
            </template>
            <template v-slot:item.time="{ item }">
                {{formatTime(item.time)}}
            </template>
			<template v-slot:item.content="{ item }">
				<vue-json-pretty :data="item.content" :deep="0"></vue-json-pretty>
				<!--{{getContent(item)}}-->
			</template>
        </v-data-table>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
	import VueJsonPretty from 'vue-json-pretty';
	import 'vue-json-pretty/lib/styles.css';
	import {formatTime, formatDate} from '../utils'

    export default {
        data() {
            return {
                authed: false,
				menu1: false,
				pdates: [],
                headers: [
                    {text:'时间', value:'time', width:180},
                    {text:'用户名', value:'customUserId', width:120},
                    {text:'ClientIP', value:'clientIP', width:100},
                    {text:'主题', value:'title', width:180},
                    {text:'内容', value:'content', width:300, sortable: false},
                ],
                items: [],
				loading: false,
				options: {},
				totalItems: 0,
				orderid: '',
				title:'',
//				cond:'',
            }
        },
		components: {
    		VueJsonPretty
		},
		created() {
			this.formatTime = formatTime;
		},
        mounted() {
			this.authed = this.$hasPrivilege('日志');
			if (!this.authed) return;
			const d = formatDate(Date.now());
			this.pdates = [d, d];
			this.fetchData();
        },
        methods: {
			async loadData() {
				this.selected = [];
				if (this.options.page !== 1) {
					this.options.page = 1;	//自动触发fetchData
					return;
				}
				await this.fetchData();
			},
			async fetchData() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				let filter = [];
				if (this.pdates.length === 2) {
					const d1 = new Date(this.pdates[0] + 'T00:00:00+08:00');
					const d2 = new Date(this.pdates[1] + 'T23:59:59+08:00');
					filter.push({time:_.gt(d1).lt(d2)});
				}
				if (this.orderid) {
					filter.push(_.or([{"content.id": this.orderid}, {"content.orderid": this.orderid}]));
				}
				if (this.title) {
					filter.push({title:this.title});
				}
				/*
				if (this.cond) {
					const str = `({content:{${this.cond}}})`;
					filter.push(eval(str));
				}
				*/
				const f = filter.length ? _.and(filter) : {};
				this.loading = true;
				try {
                    const countRes = await db.collection('wp3log').where(f).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp3log').where(f).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					this.items = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
			/*
			getContent(item) {
				if (!item.content) return "";
				if (Object.prototype.toString.call(item.content) === '[object Object]') {
					return JSON.stringify(item.content);
				}
				return item.content;
			}
			*/
		},
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			},
			pdates(ds) {
				if (ds.length === 1) return;
				if (ds.length === 2) {
					if (new Date(ds[0]) > new Date(ds[1])) {
						[ds[0], ds[1]] = [ds[1], ds[0]];
					}
				}
				this.loadData();
			},
		}
    }
</script>
